
import { defineComponent, ref, unref } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import iEmail from '@/assets/icons/Email.svg'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        'icon-email': iEmail,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['send-code', 'back'],
    setup(_, { emit }) {
        const email = ref(null)

        const emitBack = () => {
            emit('back')
        }

        const emitSendCode = () => {
            emit('send-code', unref(email))
        }

        return { emitBack, emitSendCode, email }
    },
})
