import { DIContainer } from '@/core/classes/DIContainer.class'
import { useInjectStrict } from './useInjectStrict'

export const useContainer = <Type>(interface_key: string) => {
    const { injectStrict } = useInjectStrict()

    const container: DIContainer = injectStrict('container')
    const containerInterface: Type = container.interface<Type>(interface_key)

    return containerInterface
}
