import { inject } from 'vue'

export const useInjectStrict = () => {
    function injectStrict<T>(key: string, fallback?: T) {
        const resolved = inject(key, fallback)
        if (!resolved) {
            throw new Error(`[injectStrict] Could not resolve ${key}`)
        }

        return resolved
    }

    return { injectStrict }
}
