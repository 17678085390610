
import { defineComponent, reactive, toRaw } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import SPassword from '@/common/components/SPassword/index.vue'

import iEmail from '@/assets/icons/Email.svg'
import iPassword from '@/assets/icons/Password.svg'

export default defineComponent({
    components: {
        's-button': SButton,
        's-input': SInput,
        's-password': SPassword,
        'icon-email': iEmail,
        'icon-password': iPassword,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['restore', 'signin'],
    setup(props, { emit }) {
        const credentialsState: { login: string | null; password: string | null } = reactive({
            login: null,
            password: null,
        })

        const emitSignin = () => {
            if (!credentialsState.login || !credentialsState.password || props.loading) return
            emit('signin', toRaw(credentialsState))
        }

        const emitRestore = () => {
            if (props.loading) return
            emit('restore')
        }

        return { credentialsState, emitSignin, emitRestore }
    },
})
