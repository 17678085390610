
import { defineComponent } from 'vue'

import iLogo from '@/assets/icons/Logo.svg'

export default defineComponent({
    components: {
        'icon-logo': iLogo,
    },
})
