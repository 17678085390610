
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

import AuthFormWrapper from '@/modules/Auth/components/AuthFormWrapper/index.vue'
import LoginForm from '@/modules/Auth/components/LoginForm/index.vue'
import RestoreForm from '@/modules/Auth/components/RestoreForm/index.vue'
import ConfirmationForm from '@/modules/Auth/components/ConfirmationForm/index.vue'

import { useAuthorization } from '@/common/composable/useAuthorization'
import { useAsyncFetch } from '@/common/composable/useAsyncFetch'
import { AuthorizationSignInPayloadT } from '@/core/types/Authorization.types'
import { useContainer } from '@/common/composable/useContainer'
import { AuthorizationRealisation } from '@/core/realisations/Authorization.realisation'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useTitle } from 'vue-composable'
import useAccount from '@/common/composable/useAccount'

export default defineComponent({
    components: {
        'auth-form-wrapper': AuthFormWrapper,
        'login-form': LoginForm,
        'restore-form': RestoreForm,
        'confirmation-form': ConfirmationForm,
    },
    setup() {
        useTitle('Авторизация')

        const notifications = useNotifications()
        const account = useAccount()

        const router = useRouter()
        const { authorizationAuthorize } = useAuthorization()

        const authroizationInterface = useContainer<AuthorizationRealisation>('auth')
        const recoveryHash: { hash: string } = reactive({ hash: '' })

        const authState = reactive({
            isLoginForm: true,
            isRestoreForm: false,
            isConfirmationForm: false,
        })

        const setLoginForm = () => {
            authState.isLoginForm = true
            authState.isRestoreForm = false
            authState.isConfirmationForm = false
        }

        const setRestoreForm = () => {
            authState.isLoginForm = false
            authState.isRestoreForm = true
            authState.isConfirmationForm = false
        }

        const setConfirmationForm = () => {
            authState.isLoginForm = false
            authState.isRestoreForm = false
            authState.isConfirmationForm = true
        }

        const signinHandler = async (credentilas: AuthorizationSignInPayloadT) => {
            try {
                await authorizationAuthorize(credentilas)
                await account.initialize()

                await router.replace({ name: 'Create' })
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка авторизации',
                    },
                    error
                )
            }
        }

        const recoveryHandler = async (email: string) => {
            try {
                const recoveryResponse = await authroizationInterface.recovery(email)
                recoveryHash.hash = recoveryResponse.data.hash
                setConfirmationForm()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка восстановления пароля',
                    },
                    error
                )
            }
        }

        const confirmHandler = async (payload: { code: number; newPassword: string }) => {
            try {
                await authroizationInterface.confirmRecoveryCode({
                    hash: recoveryHash.hash,
                    code: payload.code,
                    newPassword: payload.newPassword,
                })

                setLoginForm()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка подтверждения кода',
                    },
                    error
                )
            }
        }

        const loginFormState = useAsyncFetch(signinHandler)
        const recoveryFormState = useAsyncFetch(recoveryHandler)
        const confirmFormState = useAsyncFetch(confirmHandler)

        return {
            authState,
            setLoginForm,
            setRestoreForm,
            loginFormState,
            recoveryFormState,
            confirmFormState,
        }
    },
})
