import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "SPassword__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_eye = _resolveComponent("icon-eye")!
  const _component_icon_eye_off = _resolveComponent("icon-eye-off")!

  return (_openBlock(), _createBlock("div", {
    class: ["SPassword", { 'with-icon': _ctx.$slots.icon, invalid: _ctx.isInvalid }]
  }, [
    (_ctx.$slots.icon)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon")
        ]))
      : _createCommentVNode("", true),
    _createVNode("input", {
      class: ["default-input", { 'with-icon': _ctx.$slots.icon }],
      type: _ctx.inputType,
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitInput && _ctx.emitInput(...args))),
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args)))
    }, null, 42, ["type", "placeholder", "value"]),
    _createVNode("div", {
      class: "SPassword__action",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleVisible && _ctx.toggleVisible(...args)))
    }, [
      (!_ctx.isHidden)
        ? (_openBlock(), _createBlock(_component_icon_eye, { key: 0 }))
        : (_openBlock(), _createBlock(_component_icon_eye_off, { key: 1 }))
    ])
  ], 2))
}