
import { computed, defineComponent, ref } from 'vue'

import iEye from '@/assets/icons/Eye.svg'
import iEyeOff from '@/assets/icons/EyeOff.svg'

export default defineComponent({
    components: {
        'icon-eye': iEye,
        'icon-eye-off': iEyeOff,
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: null,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        const isHidden = ref(true)

        const toggleVisible = () => {
            isHidden.value = !isHidden.value
        }

        const inputType = computed(() => {
            if (isHidden.value) return 'password'
            return 'text'
        })

        const emitInput = (event: Event) => {
            const target = event.target as HTMLInputElement
            emit('update:value', target.value)
        }

        const emitChange = (event: Event) => {
            const target = event.target as HTMLInputElement
            emit('change', target.value)
        }

        return { isHidden, toggleVisible, inputType, emitInput, emitChange }
    },
})
