import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "AuthFormWrapper" }
const _hoisted_2 = { class: "AuthFormWrapper__slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_logo = _resolveComponent("icon-logo")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_icon_logo, { class: "AuthFormWrapper__logo" }),
    _createVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}