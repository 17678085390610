import { reactive } from 'vue'

export const useAsyncFetch = <Type>(callback: any) => {
    type asyncFetchStateType = {
        is_loading: boolean
        is_success: boolean
        is_failed: boolean
        is_done: boolean
        data: Type | null
    }

    const state: asyncFetchStateType = reactive({
        is_loading: false,
        is_success: false,
        is_failed: false,
        is_done: false,
        data: null,
    })

    async function asyncFetch(args?: any): Promise<void> {
        try {
            state.is_loading = true

            state.data = await callback(args)

            state.is_success = true
            state.is_failed = false
        } catch (error) {
            console.error(error)

            state.is_failed = true
            state.is_success = false
        } finally {
            state.is_loading = false
            state.is_done = true
        }
    }

    return { state, asyncFetch }
}
