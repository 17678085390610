
import { defineComponent, reactive } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import SPassword from '@/assets/icons/Password.svg'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        'icon-password': SPassword,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['back', 'confirm'],
    setup(props, { emit }) {
        const confirmationState = reactive({
            code: null,
            newPassword: null,
        })

        const emitBack = () => {
            if (props.loading) return
            emit('back')
        }

        const emitConfirm = () => {
            if (props.loading) return

            const payload = {
                code: Number(confirmationState.code),
                newPassword: confirmationState.newPassword,
            }

            emit('confirm', payload)
        }

        return { confirmationState, emitBack, emitConfirm }
    },
})
